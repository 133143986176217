import React from 'react';
import './Login.scss';
import tagline from 'img/tagline.png';
import { Link, RouteComponentProps } from 'react-router-dom';
import Auth from 'services/auth';
import * as Icons from '../../shared/svg';
import FormIconInput from '../../shared/FormIconInput';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AuthService from 'services/auth';
import ForgotPasswordModal from './ForgotPasswordModal';
import { Releases } from 'models/Release';
import ApiService from 'services/api';
import State from 'services/state';
import Whitelabel from 'models/Whitelabel';
const env: any = process.env;
const release = env.REACT_APP_RELEASE;

interface LoginProps extends RouteComponentProps {
    error: string,
    status: string,
    isForgotPasswordModalOpen: boolean,
    whitelabel: Whitelabel | null
}

interface LoginState {
    error: string,
    status: string,
    isForgotPasswordModalOpen: boolean,
    whitelabel: Whitelabel | null
}
/**
* @name Login
* @props LoginProps
* @summary Routed Component that renders the login form. Redirects here if user is not logged in.
*/

class Login extends React.Component<LoginProps> {
    state: LoginState = {
        error: '',
        status: '',
        isForgotPasswordModalOpen: false,
        whitelabel: null
    };

    componentWillMount() {
        AuthService.logout();
        State.getWhitelabel().then(wl => this.setState({whitelabel: wl}))
    }

    handleSubmit = (values: any, actions: any) => {
        this.setState({error: ''});
        Auth.login({email: values.email, password: values.password})
            .then(res => {
                ApiService.getPrimaryUserCar()
                    .then(res => {
                        if (!res.data) {
                            this.props.history.push('/car-picker');
                        } else {
                            if (Auth.getRedirectUrl()) {
                                const redirectUrl = Auth.getRedirectUrl();
                                Auth.setRedirectUrl(null);
                                this.props.history.push(redirectUrl!);
                            } else {
                                this.props.history.push('/dashboard');
                            }
                        }
                    })
                    .catch(_ => {
                        this.props.history.push('/car-picker');
                    });

            })
            .catch(res => {this.setState({error: 'Incorrect Email or Password'})});
    }

    loginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Required'),
        password: Yup.string()
            .required('Required')
    });

    handleForgotPasswordSubmit = (res: any) => {
        // error
        if (res.response && res.response.data) {
            this.setState({error: res.response.data});
        }
        else if (res.data === 'Success!') {
            this.setState({status: 'Please Check Your Email for Instructions!', error: ''});
        }
    }

    getLogoSrc = (): string => {
       return this.state.whitelabel ? this.state.whitelabel.path : '';
    }

    render() {
        return (
            <div id="login-scene" className="scene-layout">
                
                <img id="logo" src={this.getLogoSrc()} alt="logo" />
                {release === Releases.TEQ_CHARGING && <img id="tagline" src={tagline} alt="charge smarter" /> }
                {this.state.error && <div className="alert danger">Error: {this.state.error}</div> }
                {this.state.status && <div className="alert success">Success: {this.state.status}</div> }
                <Formik
                  initialValues={{email: '', password: ''}}
                  onSubmit={this.handleSubmit}
                  validationSchema={this.loginSchema}
                >
                {(formikProps: any) => (
                <Form>
                    <Field name="email"
                        icon={Icons.EmailIcon}
                        placeholder="Email"
                        type="email"
                        component={FormIconInput} />
                    <Field name="password"
                        icon={Icons.PasswordIcon}
                        placeholder="password"
                        type="password"
                        component={FormIconInput} />
                    <button className="btn btn-link" type="button" onClick={() => this.setState({isForgotPasswordModalOpen: true})}>Forgot password?</button>
                    <button className="btn btn-primary" type="submit">Login</button>
                </Form>
                )}
                </Formik>
                <div className="divider"></div>
                Don't have an account?
                <Link to='/sign-up' className="btn btn-link"><button className="btn btn-secondary">Create an Account</button></Link>
                <ForgotPasswordModal
                    isOpen={this.state.isForgotPasswordModalOpen}
                    onRequestClose={() => this.setState({isForgotPasswordModalOpen: false})}
                    onSubmit={this.handleForgotPasswordSubmit} />
            </div>
        )
    }
}
export default Login;
