import React from 'react';
import ValidationCheckbox from 'shared/ValidationCheckbox/ValidationCheckbox';
import { Formik, Form, Field } from 'formik';
import FormInput from 'shared/FormInput';
import * as Yup from 'yup';
import './style.scss';

interface ProfileFormProps {
    onSubmit: (values: any[]) => void,
    initialValues?: any,
    showDisclaimer?: boolean,
    includePassword?: boolean,
    submitText: string
}
export default class ProfileForm extends React.Component<ProfileFormProps> {
    state = {
        passwordValidationErrors: ['8Min', 'Required', '1Capital', '1Special', '1Num'],
        profileError: null
    }
    passwordValidation = Yup.string()
        .min(8, '8Min')
        .matches(/.*[0-9].*/, '1Num')
        .matches(/.*[A-Z]+.*/, '1Capital')
        .matches(/.*(?=.*[!@#$%^&*]).*/, '1Special')
        .required('Required');

    profileSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, 'Please enter a valid phone number.')
            .required('Required'),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Required')
    });

    profileWithPasswordSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Required'),
        phone: Yup.string()
            .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, 'Please enter a valid phone number.')
            .required('Required'),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Required'),
        password: this.passwordValidation
    });

    passwordSchema = Yup.object().shape({
        password: this.passwordValidation
    });

    handlePasswordChange = (e: any) => {
        try {
            this.passwordSchema.validateSync({password: e.target.value}, {abortEarly: false});
            this.setState({passwordValidationErrors: []});
        } catch (valErrors) {
            this.setState({passwordValidationErrors: valErrors.errors});
        }
    };
    onSubmit = (values: any, actions: any) => {
        this.props.onSubmit(values);
        actions.setSubmitting(false);
    }
    render() {
        const includePassword = this.props.includePassword !== undefined ? this.props.includePassword : true;
        const initialValues = this.props.initialValues || {fullName: '', phone: '', email: '', password: ''}
        const validationSchema = includePassword ? this.profileWithPasswordSchema : this.profileSchema;
        return (
                <div className="profile-form">
                <Formik
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  validationSchema={validationSchema}
                >
                {(formikProps: any) => (
                <Form>
                    <h6>Full Name</h6>
                    <Field name="fullName" component={FormInput}/>
                    <h6>Email</h6>
                    <Field name="email" type="email" component={FormInput}/>
                    <h6>Phone Number</h6>
                    <Field name="phone" render={({ field, form }: any) => {
                      return <FormInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...field}
                        form={form}
                        field={field}
                        placeholder='(     )       -' />
                    }}/>
                    {includePassword &&
                    <div className="password-wrapper">
                        <h6>Password</h6>
                        <Field type="password" name="password" onChange={(e: any) => {formikProps.handleChange(e); this.handlePasswordChange(e);}}/>
                        <div className="password-validation">
                            Must contain at least:
                            <ul>
                                <li><ValidationCheckbox valid={this.state.passwordValidationErrors.findIndex(err => err === '1Capital') === -1}/>1 capital letter</li>
                                <li><ValidationCheckbox valid={this.state.passwordValidationErrors.findIndex(err => err === '1Special') === -1}/>1 special character (!@#$%^&*)</li>
                                <li><ValidationCheckbox valid={this.state.passwordValidationErrors.findIndex(err => err === '1Num') === -1}/>1 number</li>
                                <li><ValidationCheckbox valid={this.state.passwordValidationErrors.findIndex(err => err === '8Min') === -1}/>8 characters</li>
                            </ul>
                        </div>
                    </div>
                    }
                    {this.props.showDisclaimer &&
                    <div className="disclaimer">
                        By creating an account you are agreeing to our Terms of Agreement and Privacy Policy.
                    </div>
                    }
                    <button type="submit" className="btn btn-primary">{this.props.submitText}</button>
                </Form>
                )}
                </Formik>
                </div>
        );
    };
}