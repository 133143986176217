import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    RouteProps
} from 'react-router-dom';
import Login from './scenes/Login/Login';
import SignUp from './scenes/SignUp/SignUp';
import CarPicker from './scenes/CarPicker/CarPicker';
import Dashboard from './scenes/Dashboard/Dashboard';
import ChargerPicker from './scenes/ChargerPicker/ChargerPicker';
import TimePicker from './scenes/TimePicker/TimePicker';
import Payment from './scenes/Payment/Payment';
import ChargeStarted from './scenes/ChargeStarted/ChargeStarted';
import AuthService from 'services/auth';
import Account from 'scenes/Account/Account';
import EditProfile from 'scenes/EditProfile/EditProfile';
import EditCar from 'scenes/EditCar/EditCar';
import Privacy from 'scenes/Privacy/Privacy';
import ApiService from 'services/api';

interface PrivateRouteProps extends RouteProps {
    component: new (props: any) => React.Component;
};

const PrivateRoute = ({ component: Component, ...rest}: PrivateRouteProps)  => {
    // set redirect if unauthenticated
    const authenticated = AuthService.isAuthenticated();
    if (!authenticated) {
        const props: any = rest;
        AuthService.setRedirectUrl(props.location.pathname);
    }
    return (
        <Route {...rest} render={(props) => (
            authenticated
                ? <Component {...props} />
                : <Redirect to="/login" />
        )}/>
    );
};



function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path='/login' component={Login}/>
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/privacy-policy" component={Privacy} />
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/account" component={Account} />
                <PrivateRoute exact path="/account/profile" component={EditProfile} />
                <PrivateRoute exact path="/account/car/:id" component={EditCar} />
                <PrivateRoute exact path="/car-picker" component={CarPicker} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path='/charger-picker' component={ChargerPicker} />
                <PrivateRoute exact path='/time-picker' component={TimePicker} />
                <PrivateRoute exact path='/qr/:chargerId' component={TimePicker} />
                <PrivateRoute exact path='/new-payment' component={Payment} />
                <PrivateRoute exact path='/charge-started' component={ChargeStarted} />
            </Switch>
        </Router>
    )
}

export default Routes;