import React from 'react'
import NavHeader from '../../shared/NavHeader';
import './CarPicker.scss';
import IconInput from '../../shared/IconInput';
import { SearchIcon } from '../../shared/svg';
import CarOption from './CarOption';
import Car from 'models/Car';
import ApiService from 'services/api';
import { RouteComponentProps } from 'react-router';
import UserCarRequest from 'models/UserCarRequest';
import Auth from 'services/auth';

interface CarPickerProps extends RouteComponentProps {}
interface CarPickerState {
    cars: Car[],
    groupedCars: any,
    carSearchResults: Car[],
    query: string,
    selectedCar: Car | null,

}
/**
* @name CarPicker
* @props CarPickerProps
* @summary Routed Component that renders the Car Selector.
        Redirects here if current user doesn't have a car attached to their account.
*/
export default class CarPicker extends React.Component<CarPickerProps> {
    readonly state: CarPickerState = {
        cars: [],
        carSearchResults: [],
        groupedCars: {},
        query: '',
        selectedCar: null
    };

    componentWillMount() {
        ApiService.getCars().then(res => {
            const groupedCars = {popular: res.data.filter(c => c.isPopular).sort(c => c.rank) ,...this.groupByMake(res.data)};
            this.setState({
                cars: res.data,
                groupedCars: groupedCars,
                carSearchResults: [],
                popularCars: res.data.find(c => c.isPopular)
            })
        });
    }

    groupByMake = (array: any[]) =>
        array.reduce((objectsByKeyValue, obj) => {
            const value = obj['make'];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});

    handleSaveCar = () => {
        if (!this.state.selectedCar) {
            return;
        }
        const selectedCar: Car = this.state.selectedCar!;
        const userCarRequest: UserCarRequest = {
            make: selectedCar.make,
            model: selectedCar.model,
            carId: selectedCar.id,
            isPrimary: true,
            nickname: `My ${selectedCar.model}`
         }
        ApiService.createUserCar(userCarRequest).then(res => {
            if (Auth.getRedirectUrl()) {
                const redirectUrl = Auth.getRedirectUrl();
                Auth.setRedirectUrl(null);
                this.props.history.push(redirectUrl!);
            } else {
                this.props.history.push('/dashboard');
            }
        });
    }

    handleSearchCar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query: string = event.target.value;
        this.setState({query}, () => {
            setTimeout(() => {
                if (this.state.query === query) {
                    if (query === '') {
                        this.setState({carSearchResults: []});
                        return;
                    }
                    const carSearchResults = this.state.cars.filter(car =>
                        car.make.toLowerCase().includes(query.toLowerCase()) || car.model.toLowerCase().includes(query.toLowerCase()));
                    this.setState({carSearchResults});
                }
            }, 200);
        });
    }

    render() {
        let cars: any = [];
        const { selectedCar } = this.state;
        const isCarSelected = (selectedCar !== null);
        if (this.state.carSearchResults.length > 0) {
            cars = this.state.carSearchResults.map((car: Car, index: number) => (
                <CarOption key={index}
                    car={car}
                    onSelected={(car: Car) => {
                        let sCar: Car | null = car;
                        if (isCarSelected && selectedCar!.id === car.id) {
                            sCar = null;
                        }
                        this.setState({selectedCar: sCar});
                    }}
                    selected={isCarSelected && car.id === selectedCar!.id}
                    notSelected={isCarSelected && selectedCar!.id !== car.id}/>
            ));
            cars = (<section>
                        <h6>Search Results</h6>
                        <div className="car-options">
                            {cars}
                        </div>
                    </section>);
        } else if(this.state.groupedCars) {
            Object.keys(this.state.groupedCars).forEach((make, index) => {
                const carOptions = this.state.groupedCars[make].map((car: Car, i: number) => (
                    <CarOption key={i}
                        car={car}
                        onSelected={(car: Car) => {
                            let sCar: Car | null = car;
                            if (isCarSelected && selectedCar!.id === car.id) {
                                sCar = null;
                            }
                            this.setState({selectedCar: sCar});
                        }}
                        selected={isCarSelected && car.id === selectedCar!.id}
                        notSelected={isCarSelected && selectedCar!.id !== car.id}/>
                    ));
                cars.push(
                    <section key={index}>
                        <h6>{make === 'popular' ? 'Most Popular' : make}</h6>
                        <div className="car-options">
                            {carOptions}
                        </div>
                    </section>
                )
            });
        }
        return (
            <div id="car-picker-scene" className="scene-layout">
                <NavHeader />
                <h2>What are you driving?</h2>
                <IconInput className="search-input" placeholder="Search Make / Model" icon={SearchIcon} onChange={this.handleSearchCar} />
                {cars}
                {isCarSelected &&
                <button className="btn btn-primary" onClick={this.handleSaveCar}>Save As My Car</button>
                }
            </div>
        );
    }
}