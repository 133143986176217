export default interface ModalProps {
    onRequestClose: any,
    isOpen: boolean
}

export const defaultModalStyles = {
  content : {
    top: '25%',
    margin: '0 auto',
    right: '10%',
    maxHeight: '376px',
    maxWidth: '336px',
    borderRadius: '10px'
  }
};