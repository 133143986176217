import User from "models/User";
import ApiService from "./api";
import { Releases } from 'models/Release';
import { env } from "process";
import Whitelabel from "models/Whitelabel";
const chargerKey = 'active_charger';
const profileKey = 'profile_key';
const whitelabelKey = 'whitelabel_key';
const colorKey = 'color_key';
const release = env.REACT_APP_RELEASE;

/**
 * @name State
 * @summary Service that controls the global app state.
 */
const State = {
    getCurrentCharger: () => sessionStorage.getItem(chargerKey) ? JSON.parse(sessionStorage.getItem(chargerKey)!) : null,
    setCurrentCharger: (charger: any) => sessionStorage.setItem(chargerKey, JSON.stringify(charger)),
    removeCurrentCharger: () => sessionStorage.removeItem(chargerKey),

    getProfile: (): Promise<User | null> => {
        return new Promise(resolve => {
            if (sessionStorage.getItem(profileKey)) {
                resolve(JSON.parse(sessionStorage.getItem(profileKey)!));
            } else {
                ApiService.getProfile()
                    .then(res => {
                        State.setProfile(res.data);
                        resolve(res.data);
                    })
                    .catch(_ => resolve(null));
            }
        });

    },
    setProfile: (profile: User) => sessionStorage.setItem(profileKey, JSON.stringify(profile)),
    removeProfile: () => sessionStorage.removeItem(profileKey),
    getWhitelabel: (): Promise<Whitelabel | null> => {
        return new Promise(resolve => {
            if (sessionStorage.getItem(whitelabelKey)) {
                resolve(JSON.parse(sessionStorage.getItem(whitelabelKey)!));
            } else {
                const subdomain = window.location.host.split(".")[0];
                if (subdomain) {
                    ApiService.getWhitelabelBySubdomain(subdomain).then(res => {
                        document.documentElement.style.setProperty('--branding-color-primary', res.data.primary_color);
                        State.setWhitelabel(res.data);
                        resolve(res.data);
                    })
                    .catch(_ => {
                        const logo = require('img/logos/teq_charging/logo.png');
                        const teq: Whitelabel = {
                            id: '',
                            company: 'TEQ Charging',
                            path: logo,
                            primary_color: '#3498DB',
                            secondary_color: '#3B4B56'
                        }
                        document.documentElement.style.setProperty('--branding-color-primary', teq.primary_color);
                        State.setWhitelabel(teq);
                        resolve(teq);
                    });
                }
            }
        });
    },
    setWhitelabel: (whitelabel: Whitelabel) => sessionStorage.setItem(whitelabelKey, JSON.stringify(whitelabel)),
    setColor: (color: string) => sessionStorage.setItem(colorKey, color),
    clear: () => sessionStorage.clear()
}
export default State;