import React from 'react';
import './styles/theme.scss';
import Routes from './routes';
import {StripeProvider} from 'react-stripe-elements';

const env: any = process.env;
const App: React.FC = () => {
  return (
    <StripeProvider apiKey={env.REACT_APP_STRIPE_KEY}>
      <Routes />
    </StripeProvider>
  );
}

export default App;
