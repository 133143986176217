import React from 'react';
import NavHeader from '../../shared/NavHeader';
import './Payment.scss';
import CreditCardForm from '../../shared/CreditCardForm';
import { Elements } from 'react-stripe-elements';
import { RouteComponentProps } from 'react-router';

interface PaymentProps extends RouteComponentProps {}
/**
* @name Payment
* @props PaymentProps
* @summary Routed Component that renders the payment form.
*/
export default class Payment extends React.Component<PaymentProps> {
    onSubmit = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div id="payment-scene" className="scene-layout">
                <NavHeader />
                <h2>Add Payment Method</h2>
                <Elements>
                    <CreditCardForm onSubmit={this.onSubmit} />
                </Elements>
            </div>
        );
    }
}