import React from 'react';
import { CloseIcon } from 'shared/svg';
import Modal from 'react-modal';
import './style.scss';
import ModalProps from 'models/ui/ModalProps';

interface ConfirmModalProps extends ModalProps {
    text: string,
    subjectText?: string,
    confirmText?: string,
    cancelText?: string,
    onConfirm: Function
}
export default class ConfirmModal extends React.Component<ConfirmModalProps> {
    modalStyles = {
      content : {
        top: '25%',
        bottom: 'none',
        margin: '0 auto',
        right: '10%',
        maxHeight: '376px',
        maxWidth: '336px',
        borderRadius: '10px'
      }
    };
    onConfirm = () => {
        this.props.onRequestClose();
        this.props.onConfirm();
    }
    render() {
        const {isOpen, onRequestClose, text, subjectText} = this.props;
        const confirmText = this.props.confirmText || 'Delete';
        const cancelText = this.props.cancelText || 'Cancel';
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={this.modalStyles}>
                <div className="modal-content confirm-modal-content">
                    <div className="close" onClick={onRequestClose}><CloseIcon /></div>
                    <h3>Are you sure?</h3>
                    {text} {subjectText && <b>subjectText</b>}
                    <div className="btn-group">
                        <button className="btn btn-action" onClick={onRequestClose}>{cancelText}</button>
                        <button className="btn btn-danger" onClick={this.onConfirm}>Yes, {confirmText}</button>
                    </div>
                </div>
            </Modal>
        );
    }
}