import React from 'react';
import NavHeader from '../../shared/NavHeader';
import { PlugIcon, LocationIcon } from '../../shared/svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import ApiService from 'services/api';
import './Dashboard.scss';
import moment from 'moment';
import { ChargeSession } from 'models/ChargeSession';
import UserCar from 'models/UserCar';
import ConfirmModal from 'shared/ConfirmModal';
import { CarLogoImage, CarImage } from 'shared/CarImage';
import Whitelabel from 'models/Whitelabel';
import State from 'services/state';

interface DashboardProps extends RouteComponentProps {}
interface DashboardState {
    currentSession?: ChargeSession,
    primaryCar?: UserCar,
    countdownHours: string,
    countdownMinutes: string,
    countdownSeconds: string,
    loading: boolean,
    isConfirmModelOpen: boolean,
    whitelabel: Whitelabel | null
}
/**
 * @name Dashboard
 * @props DashboardProps
 * @summary Routed Component that renders the dashboard
 */
export default class Dashboard extends React.Component<DashboardProps> {
    readonly state: DashboardState = {
        currentSession: undefined,
        loading: false,
        primaryCar: undefined,
        countdownHours: '',
        countdownMinutes: '',
        countdownSeconds: '',
        isConfirmModelOpen: false,
        whitelabel: null
    }

    componentWillMount() {
        State.getWhitelabel().then(wl => this.setState({whitelabel: wl}))
        ApiService.getPrimaryUserCar().then(res => {
            if (!res.data) {
                this.props.history.push('/car-picker');
            }
            this.setState({primaryCar: res.data});
        });
        this.setState({loading: true}, () => {
            ApiService.getChargeSessions()
            .then(res => {
                if (res.data && res.data.length > 0) {
                    this.setState({currentSession: res.data[0], loading: false}, () =>{
                        this.calculateCountdown();
                        setInterval(this.calculateCountdown, 1000);
                    });
                }
            })
            .finally(() => this.setState({loading: false}));
        });
    }

    componentWillUnmount() {
        clearInterval();
    }

    cancelSession = () => {
        this.setState({isConfirmModelOpen: true});
    }

    onConfirmCancelSession = () => {
        if (!this.state.currentSession) {
            return;
        }
        ApiService.deleteChargeSession(this.state.currentSession!.charger.serial_number)
            .then(res => this.setState({currentSession: null}));
    }

    calculateCountdown = () => {
        if (!this.state.currentSession) {
            return;
        }
        const now = moment(new Date());
        const endTime = moment(this.state.currentSession!.end_time);
        const duration = moment.duration(endTime.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        if (hours + minutes + seconds === 0) {
            this.setState({currentSession: null});
            return;
        }
        this.setState({
            countdownHours: hours >= 10 ? hours+'' : '0' + hours,
            countdownMinutes: minutes >= 10 ? minutes+'' : '0' + minutes,
            countdownSeconds: seconds >= 10 ? seconds+'' : '0' + seconds,
        })
    }

    onGetDirections = () => {
        if (!this.state.currentSession) {
            return;
        }
        const {lat, lon} = this.state.currentSession!.charger.branch.site;
        if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") !== -1) ||
             (navigator.platform.indexOf("iPad") !== -1) ||
             (navigator.platform.indexOf("iPod") !== -1))
            window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
        else /* else use Google */
            window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    }

    render() {
        const {currentSession} = this.state;
        let {primaryCar} = this.state;
        if (currentSession) {
            primaryCar = currentSession.user_car;
        }
        return (
            <div id="dashboard-scene" className="scene-layout">
                <NavHeader/>
                {primaryCar && <CarLogoImage className="car-logo" car={primaryCar.car} />}
                {!this.state.primaryCar && <div className="loader"></div>}
                {primaryCar && <h2>{primaryCar.nickname}</h2>}
                {primaryCar && <div className="car-details">{primaryCar.car.make} {primaryCar.car.model}</div>}
                {primaryCar && !currentSession && <CarImage size="x4" car={primaryCar.car} style={{marginTop: '24px', width: '400px'}} />}
                {primaryCar && currentSession &&
                <div className="img-container">
                    <CarImage size="x4" car={primaryCar.car} style={{width: '95%'}}/>
                    <div className="overlay">
                        <h6>Charging</h6>
                        {this.state.countdownHours.length > 0 && <h1>{this.state.countdownHours}:{this.state.countdownMinutes}:{this.state.countdownSeconds}</h1>}
                        <button className="btn btn-danger" onClick={this.cancelSession}>Cancel Charge</button>
                    </div>
                </div>}
                {!this.state.loading && currentSession &&
                <div className="location">
                    <LocationIcon fill={this.state.whitelabel ? this.state.whitelabel.primary_color : '#3498DB'}/>
                    <div className="name">{currentSession.charger.branch.site.name}</div>
                    <button className="btn btn-link" onClick={this.onGetDirections}>Get Directions</button>
                </div>
                }
                <div className="footer">
                    {!this.state.loading && !currentSession && <Link to="/charger-picker" className="btn btn-link"><button className="btn btn-mega btn-icon">Charge Car <PlugIcon fill={this.state.whitelabel ? this.state.whitelabel.secondary_color : '#2C80B8'} /></button></Link>}
                </div>
                {primaryCar && currentSession &&
                <ConfirmModal
                    isOpen={this.state.isConfirmModelOpen}
                    onRequestClose={() => {this.setState({isConfirmModelOpen: false})}}
                    onConfirm={this.onConfirmCancelSession}
                    confirmText={'Cancel'}
                    cancelText={'No'}
                    text={`You are about to stop charging ${primaryCar.nickname}.`} />
                }
            </div>
        );
    }
}