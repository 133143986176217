import React from 'react';
import { RouteComponentProps } from 'react-router';
import NavHeader from 'shared/NavHeader';
import ApiService from 'services/api';
import User from 'models/User';
import ProfileForm from 'shared/ProfileForm';
import State from 'services/state';
import './EditProfile.scss';

interface EditProfileProps extends RouteComponentProps {}
interface EditProfileState {
    error: string | null,
    initialValues?: any,
}
export default class EditProfile extends React.Component<EditProfileProps> {
    readonly state: EditProfileState = {
        error: null,
        initialValues: undefined,
    }

    componentWillMount() {
        State.getProfile()
            .then(profile => {
                if (!profile) {
                    this.props.history.push('/login');
                    return;
                }
                const initialValues: any = profile!;
                initialValues.fullName = `${profile!.firstName} ${profile!.lastName}`
                this.setState({initialValues})
            })
            .catch(() => this.props.history.push('/login'));
    }

    onSubmit = (values: any) => {
        this.setState({signUpError: null});
        const name = values.fullName.split(' ');
        const firstName = name[0];
        const lastName = name.length > 1 ? name[1] : '';
        const user: User = {
            firstName: firstName,
            lastName: lastName,
            phone: values.phone,
            email: values.email,
            password: '',
            roleName: 'driver'
        };
        ApiService.updateProfile(user)
            .then((res) => {
                if (res.data.success === false) {
                    this.setState({error: res.data.data});
                    return;
                }
                State.setProfile(user);
                this.props.history.goBack();
            })
            .catch((error) => this.setState({error}));
    }
    render() {
        return (
            <div id="edit-profile-scene" className="scene-layout">
                <NavHeader />
                <h2>Edit Profile</h2>
                {this.state.error && <div className="alert danger">{this.state.error}</div>}
                {this.state.initialValues &&
                <ProfileForm
                    submitText='Save Profile'
                    onSubmit={this.onSubmit}
                    initialValues={this.state.initialValues}
                    includePassword={false}/>
                }
            </div>
        );
    };
}