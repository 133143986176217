import React from 'react';
import { CloseIcon } from '../../../shared/svg';
import Modal from 'react-modal';
import './style.scss';
import ModalProps, { defaultModalStyles } from 'models/ui/ModalProps';

interface HoursModalProps extends ModalProps {
    hours: number,
    onHoursChange: any
}
export default class HoursModal extends React.Component<HoursModalProps> {
    render() {
        const {isOpen, hours, onHoursChange, onRequestClose} = this.props;
        const buttonNum = 15;
        const buttons = [];
        for (let i=1; i<=buttonNum; i++) {
            buttons.push(<button key={i} onClick={() => onHoursChange(i)} className={"btn btn-number " + (hours === i ? "selected" : "")}>{i}</button>)
        }
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={defaultModalStyles}>
                <div className="modal-content hours-modal-content">
                    <div className="close" onClick={onRequestClose}><CloseIcon /></div>
                    <h3>Hours</h3>
                    <button onClick={() => onHoursChange(0)} className={"btn btn-number " + (hours === 0 ? "selected" : "")}>0</button>
                    <div className="number-buttons">
                        {buttons}
                    </div>
                </div>
            </Modal>
        );
    }
}