import React from 'react';
import { RouteComponentProps } from 'react-router';
import NavHeader from 'shared/NavHeader';
import './Account.scss';
import { AddCarIcon, AddPaymentMethod, CCIcon } from 'shared/svg';
import User from 'models/User';
import UserCar from 'models/UserCar';
import ApiService from 'services/api';
import State from 'services/state';
import PaymentMethod from 'models/PaymentMethod';
import { Link } from 'react-router-dom';
import { CarImage, CarLogoImage } from 'shared/CarImage';

interface AccountProps extends RouteComponentProps {}
interface AccountState {
    profile?: User | null,
    userCars: UserCar[],
    paymentMethod?: PaymentMethod | null
}
export default class Account extends React.Component<AccountProps> {
    readonly state: AccountState = {
        profile: undefined,
        userCars: [],
        paymentMethod: undefined
    }
    componentWillMount() {
        ApiService.getUserCars().then(res => {
            if (res.data && res.data.length > 0) {
                this.setState({userCars: res.data});
            } else {
                this.setState({userCars: []});
            }
        });
        State.getProfile().then(profile => this.setState({profile}));
        ApiService.getUserPaymentMethods().then(res => {
            if (res.data && res.data.length > 0) {
                this.setState({paymentMethod: res.data[0]});
            } else {
                this.setState({paymentMethod: null});
            }
        });
    }
    makePrimary = (userCar: UserCar) => {
        const car = {...userCar};
        car.isPrimary = true
        ApiService.editUserCar(car)
            .then(() => {
                let cars = [...this.state.userCars];
                cars.forEach(c => c.isPrimary = c.id === userCar.id);
                this.setState({userCars: cars});
            });
    }
    render() {
        const {profile, userCars, paymentMethod} = this.state;
        const cars = userCars.sort((a,b) => {
            if (a.isPrimary) {
                return -1;
            }
            if (b.isPrimary) {
                return 1;
            }
            return 0;
        }).map((car, index) => (
                <div className="car" key={index}>
                    <div className="space-between car-imgs" style={{alignItems: "flex-start"}}>
                        <CarImage car={car.car} style={{height: "80px"}}/>
                        <CarLogoImage car={car.car} style={{height: "24px"}}/>
                    </div>
                    <div className="space-between">
                        <div className="lg-value">
                            {car.nickname}
                        </div>
                        <Link to={`/account/car/${car.id}`}>Edit</Link>
                    </div>
                    <div className="sub-text">
                        {car.car.make} {car.car.model}
                    </div>
                    {car.isPrimary && <h6 className="badge">Primary</h6>}
                    {!car.isPrimary && <h6 className="badge badge-blue" style={{cursor: 'pointer'}} onClick={() => this.makePrimary(car)}>Make Primary</h6>}
                </div>
            )
        );
        return (
            <div id="account-scene" className="scene-layout">
                <NavHeader/>
                <h2>Account Settings</h2>
                <div className="section-title">Profile</div>
                {profile === undefined && <div className="loader"></div>}
                {profile &&
                <section>
                    <div className="space-between">
                        <h6>Name</h6>
                        <Link to='/account/profile'>Edit</Link>
                    </div>
                    <div className="value">{profile.firstName} {profile.lastName}</div>
                    <h6>Phone</h6>
                    <div className="value">{profile.phone}</div>
                    <h6>Email</h6>
                    <div className="value">{profile.email}</div>
                    <h6>Password</h6>
                    <div className="value">********</div>
                </section>
                }
                <div className="section-title">My Car</div>
                {userCars === undefined && <div className="loader"></div>}
                {cars && cars}
                <Link className="btn btn-link car-link" to="car-picker"><button className="btn btn-action"><AddCarIcon/> Add Car</button></Link>
                <div className="section-title">Payment Method</div>
                {paymentMethod === undefined && <div className="loader"></div>}
                {paymentMethod === null &&  <Link className="btn btn-link" to="/new-payment"><button className="btn btn-action"><AddPaymentMethod/> Add Payment Method</button></Link>}
                {paymentMethod &&
                <section>
                    <div className="space-between">
                        <div className="cc-details">
                            <CCIcon />
                            <div>
                                <div className="lg-value">
                                    Ending in {paymentMethod.last4}
                                </div>
                                <div className="sub-text">
                                    Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/new-payment"><button className="btn btn-action">Change Payment Method</button></Link>
                </section>
                }
                {profile && <Link className="btn btn-link" to='/login'><button className="btn btn-danger">Logout</button></Link>}
            </div>
        )
    }
}