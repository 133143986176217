import React from 'react';
import { RouteComponentProps } from 'react-router';

interface PrivacyProps extends RouteComponentProps {}
/**
* @name Privacy
* @props LoginProps
* @summary Routed Component that renders the privacy policy.
*/
class Privacy extends React.Component<PrivacyProps> {
    componentWillMount() {

    }

    render() {
        return (
            <div style={{height: '100vh', margin:'0px', padding: '0px', overflow:'hidden'}}>
                <iframe title="Privacy Policy" src={process.env.PUBLIC_URL + '/privacy.pdf'} style={{"overflow":"hidden","height":"100%","width":"100%"}} height="100%" width="100%"></iframe>
            </div>
        );
    }
}
export default Privacy;