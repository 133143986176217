import React from 'react';
import { CloseIcon } from '../../../shared/svg';
import Modal from 'react-modal';
import './style.scss';
import ModalProps, { defaultModalStyles } from 'models/ui/ModalProps';

interface MinutesModalProps extends ModalProps {
    minutes: number,
    onMinutesChange: any
}
export default class MinutesModal extends React.Component<MinutesModalProps> {
    render() {
        const {isOpen, minutes, onMinutesChange, onRequestClose} = this.props;
        const buttonNum = 4;
        const buttons = [];
        for (let i=0; i<buttonNum; i++) {
            const val = i*15;
            buttons.push(<button key={i} onClick={() => onMinutesChange(val)} className={"btn btn-number " + (minutes === val ? "selected" : "")}>{val}</button>);
        }
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={defaultModalStyles}>
                <div className="modal-content minutes-modal-content">
                    <div className="close" onClick={onRequestClose}><CloseIcon /></div>
                    <h3>Minutes</h3>
                    {buttons}
                </div>
            </Modal>
        );
    }
}