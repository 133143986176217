import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import './style.scss';
import ApiService from 'services/api';
import { withRouter } from 'react-router';

class _CreditCardForm extends React.Component<any> {
    state = {
        name: '',
        ccInputFocused: false,
        savePayment: false,
        hasPaymentMethod: false,
        error: '',
        submitting: false,
    }

    componentWillMount() {
      ApiService.getUserPaymentMethods()
        .then(res => this.setState({hasPaymentMethod: res.data.length > 0}))
        .catch(_ => this.setState({hasPaymentMethod: false}));
    }

    handleSubmit = (e: any) => {
      e.preventDefault();
      if (this.props.stripe) {
        this.props.stripe
          .createToken({name: this.state.name})
          .then((payload: any) => {
            if (payload.error) {
              this.setState({error: payload.error.message});
              return;
            }
            this.setState({submitting: true}, () => {
              if (this.state.hasPaymentMethod) {
                ApiService.updateUserPaymentMethod(payload.token.id)
                  .then(res => {
                    if (res.data.success === false) {
                      this.setState({error: res.data.data, submitting: false});
                      return;
                    }
                    this.props.onSubmit();
                  });
              } else {
                ApiService.createUserPaymentMethod(payload.token.id)
                  .then(res => {
                    if (res.data.success === false) {
                      this.setState({error: res.data.data, submitting: false});
                      return;
                    }
                    this.props.onSubmit();
                  });
              }
            });
          });
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    };

    createOptions() {
        return {
          style: {
            base: {
              fontSize: "19px",
              color: '#3B4B56',
              border: '1.5px solid #3498DB',
              fontFamily: 'Roboto,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
              '::placeholder': {
                    color: "#868e96",
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        };
    }

    onCCInputFocus = () => {
        this.setState({ccInputFocused: true});
    }

    onCCInputBlur = () => {
        this.setState({ccInputFocused: false});
    }

    goBack = () => {
        (this.props as any).history.goBack();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="cc-form">
                {this.state.error && <div className="alert danger">Error: {this.state.error}</div> }
                <h6>Name on Card</h6>
                <input type="text" name="name" required value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                <h6>Card details</h6>
                <div className={"cc-input-wrapper " + (this.state.ccInputFocused ? 'focused':  '')}>
                    <CardElement
                        onFocus={this.onCCInputFocus}
                        onBlur={this.onCCInputBlur}
                        {...this.createOptions()}
                    />
                </div>
                <button className="btn btn-link cancel danger" onClick={this.goBack} type="button">Cancel</button>
                <button disabled={this.state.submitting} className="btn btn-primary">Save Payment Method</button>
            </form>
        );
    }
}
export default withRouter(injectStripe(_CreditCardForm));