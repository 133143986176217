import React from 'react';
import NavHeader from '../../shared/NavHeader';
import { BackArrowIcon, StripeCreditCardIcon } from '../../shared/svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import Slider from 'rc-slider';
import Modal from 'react-modal';
import HoursModal from './HoursModal';
import './TimePicker.scss';
import MinutesModal from './MinutesModal';
import ApiService from 'services/api';
import moment from 'moment';
import State from 'services/state';
import { ChargeSessionRequest } from 'models/ChargeSession';
import Charger from 'models/Charger';

enum MODAL_NAME { HOURS_MODAL, MINUTES_MODAL}
Modal.setAppElement('#root');

interface TimePickerProps extends RouteComponentProps {}
interface TimePickerState {
    selectedTime: number,
    hours: number,
    minutes: number,
    isHourModalOpen: boolean,
    isMinuteModalOpen: boolean,
    isPaymentModalOpen: boolean,
    rate?: number,
    charger: Charger | null,
    paymentMethods: any[],
    loadingPayments: boolean,
    loadingPrice: boolean
}
/**
* @name TimePicker
* @props TimePickerProps
* @summary Routed Component that renders the time selector. Payment Method can be updated here as well.
*/
export default class TimePicker extends React.Component<TimePickerProps> {
    readonly DEFAULT_TIME = 15; //minutes
    readonly MAX_TIME = this.DEFAULT_TIME * 63; // minutes
    readonly MARKS_AMOUNT = 13;
    readonly INCREMENT = this.MAX_TIME / this.MARKS_AMOUNT;

    readonly state: TimePickerState = {
        selectedTime: this.DEFAULT_TIME,
        hours: Math.floor(this.DEFAULT_TIME / 60),
        minutes: Math.floor(this.DEFAULT_TIME % 60),
        isHourModalOpen: false,
        isMinuteModalOpen: false,
        isPaymentModalOpen: false,
        rate: undefined,
        charger: null,
        paymentMethods: [],
        loadingPayments: false,
        loadingPrice: false,
    };
    // marks for the slider. Creates marks evenly distributed
    marks: any = Array.apply(null, Array(this.MARKS_AMOUNT)).map((_, index) =>
        {return {key: this.INCREMENT*(index+1), value: '|'};})
        .reduce((obj, item) => Object.assign(obj, {[item.key]: item.value}) , {});

    componentWillMount() {
        // get charger from params
        const chargerId: string = (this.props.match.params as any).chargerId;
        if (chargerId) {
            ApiService.findCharger(chargerId)
            .then(res => {
                    const charger = res.data;
                    this.setCharger(charger);
                })
                .catch(_ => {
                    this.props.history.push('/charger-picker');
                    return;
                });
        } else {
            // get charger from local state
            const charger: any = State.getCurrentCharger();
            if (!charger) {
                this.props.history.push('/charger-picker');
                return;
            }
            this.setCharger(charger);
        }
    }

    setCharger = (charger: any) => {
        this.setState({charger: charger, loadingPayments: true, loadingPrice: true}, () => {
            ApiService.getPriceGroup(charger.serial_number)
                .then((res => this.setState({rate: parseFloat(res.data)})))
                .finally(() => this.setState({loadingPrice: false}));
            ApiService.getUserPaymentMethods()
                .then(res => {this.setState({paymentMethods: res.data})})
                .catch(_ => {})
                .finally(() => this.setState({loadingPayments: false}));
        });
    }

    handleOnTimeChange = (selectedTime: number) => {
        if (selectedTime % 15) {
            selectedTime = selectedTime - (selectedTime % 15);
        }
        this.setState({
            selectedTime: selectedTime,
            hours: Math.floor(selectedTime / 60),
            minutes: Math.floor(selectedTime % 60),
        });
    }

    openModal(name: MODAL_NAME) {
        if (name === MODAL_NAME.HOURS_MODAL) {
            this.setState({isHourModalOpen: true});
        }
        else if (name === MODAL_NAME.MINUTES_MODAL) {
            this.setState({isMinuteModalOpen: true});
        }
    }

    closeModal(name: MODAL_NAME) {
        if (name === MODAL_NAME.HOURS_MODAL) {
            this.setState({isHourModalOpen: false});
        }
        else if (name === MODAL_NAME.MINUTES_MODAL) {
            this.setState({isMinuteModalOpen: false});
        }
    }

    handleOnHoursChange = (hours: number) => {
        const selectedTime = (hours * 60) + this.state.minutes;
        this.setState({
            selectedTime: selectedTime,
            hours: hours
        });
        this.closeModal(MODAL_NAME.HOURS_MODAL);
    }

    handleOnMinutesChange = (minutes: number) => {
        const selectedTime = (this.state.hours * 60) + minutes;
        if (selectedTime === 0) {
            minutes = 15; //min time
        }
        this.setState({
            selectedTime: selectedTime,
            minutes: minutes
        });
        this.closeModal(MODAL_NAME.MINUTES_MODAL);
    }

    onSubmit = () => {
        if (!this.state.charger) {
            return;
        }
        const data: ChargeSessionRequest = {
            chargerSerialNumber: this.state.charger!.serial_number,
            rate: this.state.rate!,
            seconds: this.state.selectedTime * 60
        }
        ApiService.createChargeSession(data).then(res => {this.props.history.push('/charge-started')})
    }

    onUpdatePaymentMethod = () => {
        this.setState({loadingPayments: true, paymentMethods: []}, () => {
            ApiService.getUserPaymentMethods()
            .then(res => {this.setState({paymentMethods: res.data})})
            .catch(_ => {})
            .finally(() => this.setState({loadingPayments: false}));
        });
    }

    render() {
        let price = '0.00';
        if (this.state.rate) {
            let duration = moment.duration(this.state.selectedTime, 'minutes')
            let multiplier = duration.asHours()
            price = (this.state.rate! * multiplier).toFixed(2).toString();
        }
        return (
            <div id="time-picker-scene" className="scene-layout">
                <NavHeader/>
                {this.state.charger &&
                <div id="sub-header">
                    <Link to="/charger-picker"><BackArrowIcon/></Link>
                    <div className="details">
                        <div className="name">{this.state.charger!.branch.site.name}</div>
                        <div className="charger">Charger ID #{(this.state.charger! as any).chargerId}</div>
                    </div>
                </div>
                }
                <h2>How long would you like <br/> to charge your car?</h2>
                <div className="time">
                    <button className="btn btn-number no-highlight" onClick={() => this.openModal(MODAL_NAME.HOURS_MODAL)}>{this.state.hours}</button>
                    hours,
                    <button className="btn btn-number no-highlight" onClick={() => this.openModal(MODAL_NAME.MINUTES_MODAL)}>{this.state.minutes}</button>
                    min
                </div>
                <Slider value={this.state.selectedTime} min={15} max={this.MAX_TIME} marks={this.marks} step={15} onChange={this.handleOnTimeChange} />
                <div className="cost-wrapper">
                    Estimated Cost:
                    <div className="cost">
                        {this.state.loadingPrice && <div className="loader"></div>}
                        {!this.state.loadingPrice ? `$${price}` : ''}
                    </div>
                </div>
                <div className="payment-wrapper">
                    Payment Method:
                    <div className="payment">
                        {this.state.loadingPayments && <div className="loader"></div>}
                        {!this.state.loadingPayments && this.state.paymentMethods.length === 0 && <Link to="/new-payment">+ Payment Method</Link>}
                        {this.state.paymentMethods.length > 0 &&
                        <div className="paymentMethod">
                            <div><StripeCreditCardIcon/> Ending in {this.state.paymentMethods[0].last4}</div>
                            <Link to={'/new-payment'}><button className="btn btn-link">Change Payment Method</button></Link>
                        </div>
                        }
                    </div>
                </div>
                <button className="btn btn-primary" disabled={this.state.paymentMethods.length === 0} onClick={this.onSubmit}>Start Charging</button>
                <HoursModal
                    isOpen={this.state.isHourModalOpen}
                    onRequestClose={() => this.closeModal(MODAL_NAME.HOURS_MODAL)}
                    hours={this.state.hours}
                    onHoursChange={this.handleOnHoursChange}/>
                <MinutesModal
                    isOpen={this.state.isMinuteModalOpen}
                    onRequestClose={() => this.closeModal(MODAL_NAME.MINUTES_MODAL)}
                    minutes={this.state.minutes}
                    onMinutesChange={this.handleOnMinutesChange}/>
            </div>
        );
    }
}