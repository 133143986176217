import React from 'react';
import { CloseIcon } from 'shared/svg';
import Modal from 'react-modal';
import ModalProps, { defaultModalStyles } from 'models/ui/ModalProps';
import AuthService from 'services/auth';
import { Formik, Form, Field } from 'formik';
import FormInput from 'shared/FormInput';
import * as Yup from 'yup';
import './style.scss';

interface ForgotPasswordModalProps extends ModalProps {
    onSubmit: Function
}
export default class ForgotPasswordModal extends React.Component<ForgotPasswordModalProps> {
    handleSubmit = (values: any, actions: any) => {
        const email = values.email;
        AuthService.forgotPassword(email)
            .then((res) => {
                this.props.onSubmit(res);
                this.props.onRequestClose();
            })
            .catch((res) => {
                this.props.onSubmit(res);
                this.props.onRequestClose();
            });
    }
    forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Required')
    })
    render() {
        const {isOpen, onRequestClose} = this.props;
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={defaultModalStyles}>
                <div className="modal-content forgot-password-modal-content">
                    <div className="close" onClick={onRequestClose}><CloseIcon /></div>
                    <h3>Forgot Password</h3>
                    <Formik
                      initialValues={{email: ''}}
                      onSubmit={this.handleSubmit}
                      validationSchema={this.forgotPasswordSchema}
                    >
                    {(formikProps: any) => (
                    <Form>
                        <h6>Email</h6>
                        <Field name="email"
                            placeholder="Email"
                            type="email"
                            component={FormInput} />
                        <button className="btn btn-primary" type="submit">Send</button>
                    </Form>
                    )}
                    </Formik>
                </div>
            </Modal>
        );
    }
}