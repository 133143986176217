import User from 'models/User';
import axios, { AxiosResponse } from 'axios';
import AuthService from './auth';
import { ChargeSessionRequest, ChargeSession } from 'models/ChargeSession';
import Charger from 'models/Charger';
import Car from 'models/Car';
import UserCarRequest from 'models/UserCarRequest';
import UserCar from 'models/UserCar';
import PaymentMethod from 'models/PaymentMethod';
import Whitelabel from 'models/Whitelabel';

const env: any = process.env;
const baseUrl = env.REACT_APP_API_URL+'mobile';

axios.defaults.headers.common = {'Authorization': `Bearer ${AuthService.getToken()}`};

/**
 * @name ApiService
 * @summary Service that calls out to the teq-server api.
 */
const ApiService = {
    findCharger: (chargerId: string): Promise<AxiosResponse<Charger[]>> => axios.get(`${baseUrl}/chargers/${chargerId}`),
    getPriceGroup: (serialNumber: string): Promise<AxiosResponse<string>> => axios.get(`${baseUrl}/price_groups/${serialNumber}`),
    getProfile: (): Promise<AxiosResponse<User>> => axios.get(`${baseUrl}/profiles`),
    createChargeSession: (data: ChargeSessionRequest): Promise<AxiosResponse<any>> => axios.post(`${baseUrl}/charge_sessions`, data),
    getChargeSessions: (): Promise<AxiosResponse<ChargeSession[]>> => axios.get(`${baseUrl}/charge_sessions`),
    deleteChargeSession: (serialNumber: string): Promise<AxiosResponse<any>> => axios.delete(`${baseUrl}/charge_sessions/${serialNumber}`),
    updateProfile: (data: User): Promise<AxiosResponse<any>> => axios.put(`${baseUrl}/profiles`, data),
    getCars: (): Promise<AxiosResponse<Car[]>> => axios.get(`${baseUrl}/cars`),
    getPrimaryUserCar: ():Promise<AxiosResponse<UserCar>> => axios.get(`${baseUrl}/user_cars/primary`),
    getUserCars: (): Promise<AxiosResponse<UserCar[]>> => axios.get(`${baseUrl}/user_cars`),
    createUserCar: (data: UserCarRequest) => axios.post(`${baseUrl}/user_cars`, data),
    editUserCar: (data: any) => axios.put(`${baseUrl}/user_cars`, data),
    deleteUserCar: (id: string) => axios.delete(`${baseUrl}/user_cars/${id}`),
    getUserPaymentMethods: (): Promise<AxiosResponse<PaymentMethod[]>> => axios.get(`${baseUrl}/payment_methods`),
    createUserPaymentMethod: (paymentToken: string) => axios.post(`${baseUrl}/payment_methods`, {token: paymentToken}),
    updateUserPaymentMethod: (paymentToken: string) => axios.put(`${baseUrl}/payment_methods`, {token: paymentToken}),
    deleteUserPaymentMethod: (paymentToken: string) => axios.delete(`${baseUrl}/payment_methods/${paymentToken}`),
    getWhitelabelBySubdomain: (subdomain: string): Promise<AxiosResponse<Whitelabel>> => axios.get(`${baseUrl}/whitelabels/${subdomain}`),
};

export default ApiService;