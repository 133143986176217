import React from 'react';
import NavHeader from '../../shared/NavHeader';
import './ChargerPicker.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { QrIcon, LocationIcon, NavArrowIcon } from '../../shared/svg';
import IconInput from '../../shared/IconInput';
import ApiService from 'services/api';
import State from 'services/state';
import Charger from 'models/Charger';
import QrReader from "react-qr-reader";

interface ChargerPickerProps extends RouteComponentProps {}
interface ChargerPickerState {
    searching: boolean,
    searchFocused: boolean,
    loading: boolean,
    qr: boolean,
    location: Charger | null
}
export default class ChargerPicker extends React.Component<ChargerPickerProps> {
    readonly state: ChargerPickerState = {
        searching: false,
        searchFocused: false,
        loading: false,
        location: null,
        qr: false,
    };
    searchInput!: IconInput | null;

    componentWillMount() {
        State.removeCurrentCharger();
    }

    handleActivateSearch = () => {
        this.setState({searching: true} , () => {
            if (this.searchInput) {
                this.searchInput.focus();
            }
        });
    };

    handleActivateQRSearch = () => {
        this.setState({searching: true, qr: true});
    }

    handleSearch = (e: any) => {
        // simulate API
        e.persist();
        if (e.target.value.length < 5) {
            this.setState({location: null});
            return;
        }
        this.setState({loading: true}, () => {
            ApiService.findCharger(e.target.value)
                .then(res => {
                    this.setState({loading: false, location: res.data});
                })
                .catch(_ => this.setState({loading: false, location: null}));
        });
    }

    handleBlur = (e: any) => {
        if (e.target.value.length < 5) {
            this.setState({searching: false});
        }
    }

    onLocationSelected = () => {
        State.setCurrentCharger(this.state.location);
        this.props.history.push('/time-picker');
    }

    handleScan = (data: string | null) => {
      if (data) {
        this.setState({loading: true}, () => {
            ApiService.findCharger(data)
                .then(res => {
                    this.setState({loading: false, location: res.data});
                })
                .catch(_ => this.setState({loading: false, location: null}));
        });
      }
    }

    render() {
        return (
            <div id="charger-picker-scene" className="scene-layout">
                <NavHeader/>
                <h2>Charger ID</h2>
                <div className="sub-header">See the sticker on the front of the EV station.</div>
                {!this.state.searching &&
                <div className="selection-wrapper">
                    <button className="btn btn-input" onClick={this.handleActivateSearch}>Enter 5-digit Charger ID</button>
                    <div className="or">OR</div>
                    <button className="btn btn-qr" onClick={this.handleActivateQRSearch}><QrIcon/> Scan Qr Code</button>
                </div>
                }
                {this.state.searching &&
                <div className="searching-wrapper">
                    {this.state.qr &&
                        <QrReader
                          delay={300}
                          onError={() => {}}
                          onScan={this.handleScan}
                          style={{ width: "100%" }}
                        />
                    }
                    {!this.state.qr &&
                    <IconInput
                        onChange={this.handleSearch}
                        onBlur={this.handleBlur}
                        icon={QrIcon} append={true}
                        ref={node =>this.searchInput = node}/>
                    }
                    {this.state.loading &&
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                        Searching...
                    </div>
                    }
                    {this.state.location &&
                    <div className="result" onClick={this.onLocationSelected}>
                        <div className="details-wrapper">
                            <LocationIcon/>
                            <div className="details">
                                <div className="name">{this.state.location.branch.site.name}</div>
                            </div>
                        </div>
                        <NavArrowIcon/>
                    </div>
                    }
                </div>
                }
                <Link to="/dashboard" className={'cancel danger ' + (this.state.searchFocused && 'sticky')}>Cancel</Link>
            </div>
        );
    }
}