import React from 'react';
import './style.scss';
import Car from 'models/Car';
import { CheckIcon } from 'shared/svg';
import { CarImage, CarLogoImage } from 'shared/CarImage';

interface CarOptionProps {
    car: Car,
    selected: boolean,
    notSelected: boolean,
    onSelected: Function
}
/**
 * @name CarOption
 * @props CarOptionProps
 * @summary Component that renders a single car option in the car picker component
 */
export default class CarOption extends React.Component<CarOptionProps> {
    render() {
        return (
            <div className={'car-option ' + (this.props.selected ? 'selected ' : '') + (this.props.notSelected ? 'not-selected' : '')}
                onClick={() => this.props.onSelected(this.props.car)}>
                <div className="details">
                    {this.props.car.make}
                    <CarLogoImage car={this.props.car} style={{width: '16px'}}/>
                </div>
                <h5>{this.props.car.model}</h5>
                <CarImage className="car-img" car={this.props.car} />
                {this.props.selected && <div className="check-mark"><CheckIcon color={'#FFFFFF'}/></div>}
            </div>
        );
    }
}