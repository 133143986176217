import React, { ChangeEvent, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import './EditCar.scss';
import NavHeader from 'shared/NavHeader';
import UserCar from 'models/UserCar';
import ApiService from 'services/api';
import ConfirmModal from 'shared/ConfirmModal';
import { CarImage, CarLogoImage } from 'shared/CarImage';

interface EditCarProps extends RouteComponentProps {}
interface EditCarState {
    car?: UserCar | null,
    nickname: string,
    isConfirmModalOpen: boolean
}
export default class EditCar extends React.Component<EditCarProps> {
    readonly state: EditCarState = {
        car: undefined,
        nickname: '',
        isConfirmModalOpen: false
    }
    componentWillMount() {
        ApiService.getUserCars()
            .then((res) => {
                const car = res.data.find(c => c.id === (this.props.match.params as any).id);
                if (!car) {
                    this.props.history.goBack();
                    return;
                }
                this.setState({car, nickname: car.nickname});
            })
            .catch(() => this.props.history.goBack());
    }
    handleOnSubmit = (event: FormEvent) => {
        event.preventDefault();
        let {nickname, car} = this.state;
        if (!car) {
            return;
        }
        if (!nickname) {
            nickname = `My ${car!.car.model}`;
        }
        car.nickname = nickname;
        ApiService.editUserCar(car)
            .then(res => this.props.history.goBack())
            .catch(res => {});
    }
    handleOnDeleteCar = () => {
        if (!this.state.car) {
            return;
        }
        ApiService.deleteUserCar(this.state.car!.id).then(() => this.props.history.goBack());
    }
    render() {
        const {car, nickname} = this.state;
        return (
            <div id="edit-car-scene" className="scene-layout">
                <NavHeader />
                <h2>Edit Car</h2>
                {car === undefined && <div className="loader"></div>}
                {car &&
                <div>
                    <div className="car-details">
                        <CarImage id="car-img" car={car.car} />
                        <div id="make-model">
                            <div className="make">{car.car.make}</div>
                            <h3>{car.car.model}</h3>
                        </div>
                        <CarLogoImage car={car.car} style={{width: '24px'}} />
                    </div>
                    <form onSubmit={this.handleOnSubmit}>
                        <h6>Nickname</h6>
                        <input
                            name="nickname"
                            value={nickname}
                            placeholder={`My ${car.car.model}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({nickname: event.target.value})} />
                            {!car.isPrimary && <button className="btn btn-link danger" type="button" onClick={() => this.setState({isConfirmModalOpen: true})}>Delete Car</button>}
                        <button className="btn btn-primary" type="submit">Save Car</button>
                    </form>
                </div>
                }
                <ConfirmModal
                    isOpen={this.state.isConfirmModalOpen}
                    onRequestClose={() => this.setState({isConfirmModalOpen: false})}
                    text="You are about to delete this car."
                    onConfirm={this.handleOnDeleteCar}/>
            </div>
        );
    }
}