import React from 'react';
import Car from "../models/Car";

// react native requires image paths to be loaded statically. This requires us to define image paths manually and map them
const CarImageSources = [
    {make: 'nissan', model: 'leaf', size: 'x2', src: require('../img/cars/thumbnails/x2/nissan/leaf.png')},
    {make: 'nissan', model: 'leaf', size: 'x4', src: require('../img/cars/thumbnails/x4/nissan/leaf.png')},
    {make: 'tesla', model: 'model 3', size: 'x2', src: require('../img/cars/thumbnails/x2/tesla/model3.png')},
    {make: 'tesla', model: 'model 3', size: 'x4', src: require('../img/cars/thumbnails/x4/tesla/model3.png')},
    {make: 'tesla', model: 'model x', size: 'x2', src: require('../img/cars/thumbnails/x2/tesla/modelx.png')},
    {make: 'tesla', model: 'model x', size: 'x4', src: require('../img/cars/thumbnails/x4/tesla/modelx.png')},
    {make: 'tesla', model: 'model s', size: 'x2', src: require('../img/cars/thumbnails/x2/tesla/models.png')},
    {make: 'tesla', model: 'model s', size: 'x4', src: require('../img/cars/thumbnails/x4/tesla/models.png')},
    {make: 'bmw', model: '530e', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/530e.png')},
    {make: 'bmw', model: '530e', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/530e.png')},
    {make: 'bmw', model: '740e', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/740e.png')},
    {make: 'bmw', model: '740e', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/740e.png')},
    {make: 'bmw', model: 'xdrive', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/740e.png')},
    {make: 'bmw', model: 'xdrive', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/740e.png')},
    {make: 'bmw', model: 'i3', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/i3.png')},
    {make: 'bmw', model: 'i3', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/i3.png')},
    {make: 'bmw', model: 'i8', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/i8.png')},
    {make: 'bmw', model: 'i8', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/i8.png')},
    {make: 'bmw', model: 'x5', size: 'x2', src: require('../img/cars/thumbnails/x2/bmw/x5.png')},
    {make: 'bmw', model: 'x5', size: 'x4', src: require('../img/cars/thumbnails/x4/bmw/x5.png')},
    {make: 'chevrolet', model: 'bolt', size: 'x2', src: require('../img/cars/thumbnails/x2/chevrolet/bolt.png')},
    {make: 'chevrolet', model: 'bolt', size: 'x4', src: require('../img/cars/thumbnails/x4/chevrolet/bolt.png')},
    {make: 'chevrolet', model: 'volt', size: 'x2', src: require('../img/cars/thumbnails/x2/chevrolet/volt.png')},
    {make: 'chevrolet', model: 'volt', size: 'x4', src: require('../img/cars/thumbnails/x4/chevrolet/volt.png')},
    {make: 'chrysler', model: 'pacifica', size: 'x2', src: require('../img/cars/thumbnails/x2/chrysler/pacifica.png')},
    {make: 'chrysler', model: 'pacifica', size: 'x4', src: require('../img/cars/thumbnails/x4/chrysler/pacifica.png')},
    {make: 'fiat', model: '500e', size: 'x2', src: require('../img/cars/thumbnails/x2/fiat/500e.png')},
    {make: 'fiat', model: '500e', size: 'x4', src: require('../img/cars/thumbnails/x4/fiat/500e.png')},
    {make: 'ford', model: 'c-mac energi', size: 'x2', src: require('../img/cars/thumbnails/x2/ford/cmac.png')},
    {make: 'ford', model: 'c-mac energi', size: 'x4', src: require('../img/cars/thumbnails/x4/ford/cmac.png')},
    {make: 'ford', model: 'focus ev', size: 'x2', src: require('../img/cars/thumbnails/x2/ford/focus.png')},
    {make: 'ford', model: 'focus ev', size: 'x4', src: require('../img/cars/thumbnails/x4/ford/focus.png')},
    {make: 'honda', model: 'fit ev', size: 'x2', src: require('../img/cars/thumbnails/x2/honda/fit.png')},
    {make: 'honda', model: 'fit ev', size: 'x4', src: require('../img/cars/thumbnails/x4/honda/fit.png')},
    {make: 'hyundai', model: 'ioniq', size: 'x2', src: require('../img/cars/thumbnails/x2/hyundai/ioniq.png')},
    {make: 'hyundai', model: 'ioniq', size: 'x4', src: require('../img/cars/thumbnails/x4/hyundai/ioniq.png')},
    {make: 'hyundai', model: 'sonata plug-in', size: 'x2', src: require('../img/cars/thumbnails/x2/hyundai/sonata.png')},
    {make: 'hyundai', model: 'sonata plug-in', size: 'x4', src: require('../img/cars/thumbnails/x4/hyundai/sonata.png')},
    {make: 'kia', model: 'soul ev', size: 'x2', src: require('../img/cars/thumbnails/x2/kia/soul.png')},
    {make: 'kia', model: 'soul ev', size: 'x4', src: require('../img/cars/thumbnails/x4/kia/soul.png')},
    {make: 'kia', model: 'optima', size: 'x2', src: require('../img/cars/thumbnails/x2/kia/optima.png')},
    {make: 'kia', model: 'optima', size: 'x4', src: require('../img/cars/thumbnails/x4/kia/optima.png')},
    {make: 'mercedes benz', model: 'b-class', size: 'x2', src: require('../img/cars/thumbnails/x2/mercedes/benz-bclass.png')},
    {make: 'mercedes benz', model: 'b-class', size: 'x4', src: require('../img/cars/thumbnails/x4/mercedes/benz-bclass.png')},
    {make: 'mercedes benz', model: 'gle 550e', size: 'x2', src: require('../img/cars/thumbnails/x2/mercedes/gle550e.png')},
    {make: 'mercedes benz', model: 'gle 550e', size: 'x4', src: require('../img/cars/thumbnails/x4/mercedes/gle550e.png')},
    {make: 'mercedes benz', model: 'c350e', size: 'x2', src: require('../img/cars/thumbnails/x2/mercedes/c350e.png')},
    {make: 'mercedes benz', model: 'c350e', size: 'x4', src: require('../img/cars/thumbnails/x4/mercedes/c350e.png')},
    {make: 'mitsubishi', model: 'i-miev', size: 'x2', src: require('../img/cars/thumbnails/x2/mitsubishi/imeiv.png')},
    {make: 'mitsubishi', model: 'i-miev', size: 'x4', src: require('../img/cars/thumbnails/x4/mitsubishi/imeiv.png')},
    {make: 'smart electric', model: 'drive', size: 'x2', src: require('../img/cars/thumbnails/x2/smart_electric/drive.png')},
    {make: 'smart electric', model: 'drive', size: 'x4', src: require('../img/cars/thumbnails/x4/smart_electric/drive.png')},
    {make: 'toyota', model: 'rav4 ev', size: 'x2', src: require('../img/cars/thumbnails/x2/toyota/rav4.png')},
    {make: 'toyota', model: 'rav4 ev', size: 'x4', src: require('../img/cars/thumbnails/x4/toyota/rav4.png')},
    {make: 'toyota', model: 'prius', size: 'x2', src: require('../img/cars/thumbnails/x2/toyota/prius.png')},
    {make: 'toyota', model: 'prius', size: 'x4', src: require('../img/cars/thumbnails/x4/toyota/prius.png')},
    {make: 'volkswagen', model: 'egolf', size: 'x2', src: require('../img/cars/thumbnails/x2/volkswagen/egolf.png')},
    {make: 'volkswagen', model: 'egolf', size: 'x4', src: require('../img/cars/thumbnails/x4/volkswagen/egolf.png')},
    {make: 'volvo', model: 'xc90', size: 'x2', src: require('../img/cars/thumbnails/x2/volvo/xc90.png')},
    {make: 'volvo', model: 'xc90', size: 'x4', src: require('../img/cars/thumbnails/x4/volvo/xc90.png')},
    {make: 'audi', model: 'a3', size: 'x2', src: require('../img/cars/thumbnails/x2/audi/a3.png')},
    {make: 'audi', model: 'a3', size: 'x4', src: require('../img/cars/thumbnails/x4/audi/a3.png')},
    {make: 'audi', model: 'a3 3-tron', size: 'x2', src: require('../img/cars/thumbnails/x2/audi/a3.png')},
    {make: 'audi', model: 'a3 3-tron', size: 'x4', src: require('../img/cars/thumbnails/x4/audi/a3.png')},
    {make: 'other', model: 'other', size: 'x2', src: require('../img/cars/thumbnails/x2/tesla/model3.png')},
    {make: 'other', model: 'other', size: 'x4', src: require('../img/cars/thumbnails/x4/tesla/model3.png')},
];
const CarLogoSources = [
    {make: 'nissan', src: require('../img/cars/thumbnails/x2/nissan/logo.png')},
    {make: 'audi', src: require('../img/cars/thumbnails/x2/audi/logo.png')},
    {make: 'tesla', src: require('../img/cars/thumbnails/x2/tesla/logo.png')},
    {make: 'bmw', src: require('../img/cars/thumbnails/x2/bmw/logo.png')},
    {make: 'chevrolet', src: require('../img/cars/thumbnails/x2/chevrolet/logo.png')},
    {make: 'chrysler', src: require('../img/cars/thumbnails/x2/chrysler/logo.png')},
    {make: 'fiat', src: require('../img/cars/thumbnails/x2/fiat/logo.png')},
    {make: 'ford', src: require('../img/cars/thumbnails/x2/ford/logo.png')},
    {make: 'honda', src: require('../img/cars/thumbnails/x2/honda/logo.png')},
    {make: 'hyundai', src: require('../img/cars/thumbnails/x2/hyundai/logo.png')},
    {make: 'kia', src: require('../img/cars/thumbnails/x2/kia/logo.png')},
    {make: 'mercedes benz', src: require('../img/cars/thumbnails/x2/mercedes/logo.png')},
    {make: 'mitsubishi', src: require('../img/cars/thumbnails/x2/mitsubishi/logo.png')},
    {make: 'smart electric', src: require('../img/cars/thumbnails/x2/smart_electric/logo.jpg')},
    {make: 'toyota', src: require('../img/cars/thumbnails/x2/toyota/logo.png')},
    {make: 'volkswagen', src: require('../img/cars/thumbnails/x2/volkswagen/logo.png')},
    {make: 'volvo', src: require('../img/cars/thumbnails/x2/volvo/logo.jpeg')},
    {make: 'other', src: require('../img/cars/thumbnails/x2/tesla/logo.png')},
];

export const CarImage = ({car, size = 'x2', className=undefined, id=undefined, style={}}: {car: Car, className?:string, id?:string ,size?: string, style?: any}) => {
    let source = CarImageSources.find(c => c.make === car.make.trim().toLowerCase() && c.model === car.model.trim().toLowerCase() && size === c.size);
    if (!source) {
        source = CarImageSources.find(c => c.make === 'other' && size === c.size);
    }
    return (<img className={className} id={id} src={source!.src} alt="car" style={style} />)
};

export const CarLogoImage = ({car, className=undefined, id=undefined, style={}}: {car: Car, className?:string, id?:string, size?: string, style?: any}) => {
    let source = CarLogoSources.find(c => c.make === car.make.trim().toLowerCase());
    if (!source) {
        source = CarLogoSources.find(c => c.make === 'other');
    }
    return (<img className={className} id={id} src={source!.src} alt="logo" style={style} />)
};
