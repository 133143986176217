import axios, { AxiosResponse } from 'axios';
import UserAuth from 'models/UserAuth';
import User from 'models/User';

const env: any = process.env;
const baseUrl = env.REACT_APP_API_URL+'auth';
const tokenKey = 'auth_token';
const redirectKey = 'auth_redirect'
const publicRequestConfig = {
    headers: {}
};

const Store = {
    getItem: (key: string): string | null => localStorage.getItem(key),
    setItem: (key: string, value: string | null) => {
        if (!value) {
            localStorage.removeItem(key);
            return;
        }
        localStorage.setItem(key, value!);
    },
    clear: () => localStorage.clear()
}
/**
 * @name AuthService
 * @summary Service that controls authentication state and calls out to teq-server auth.
 */
const AuthService = {

    getRedirectUrl: (): string | null => Store.getItem(redirectKey),

    setRedirectUrl: (url: string | null) => Store.setItem(redirectKey, url!),

    getToken: (): string | null => Store.getItem(tokenKey),

    isAuthenticated: () => AuthService.getToken() !== null,

    signUp: (data: User): Promise<void> => {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/signup`, {user: data}, publicRequestConfig)
            .then((res: any) => {
                Store.setItem(tokenKey, res.data.token);
                axios.defaults.headers.common = {'Authorization': `Bearer ${AuthService.getToken()}`};
                resolve();
            })
            .catch(error => reject(error));
        });
    },

    // resetPassword: (password: string): Promise<AxiosResponse<any>> => axios.post(`${baseUrl}/password/reset`, password),

    forgotPassword: (email: string): Promise<AxiosResponse<any>> => axios.get(`${baseUrl}/forgot-password/${email}`, publicRequestConfig),

    login: (data: UserAuth): Promise<void> => {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/login/mobile`, {user: data}, publicRequestConfig)
            .then((res: any) => {
                Store.setItem(tokenKey, res.data.token);
                axios.defaults.headers.common = {'Authorization': `Bearer ${AuthService.getToken()}`};
                resolve();
            })
            .catch(error => reject(error));
        });
    },

    accessCodeLogin: (accessCode: string): Promise<any> => axios.get(`${baseUrl}/complimentary-services?code=${accessCode}`),

    logout: (): Promise<void> => {
        return new Promise((resolve, _) => {
            const redirectUrl = AuthService.getRedirectUrl();
            Store.clear();
            sessionStorage.clear();
            localStorage.clear();
            if (redirectUrl) {
                AuthService.setRedirectUrl(redirectUrl);
            }
            resolve();
        });
    },
};

export default AuthService;