import React, { ChangeEvent } from 'react';
import './style.scss';

interface IconInputProps {
    icon: any,
    mask?: any[],
    append?: boolean,
    className?: string,
    handleFocus?: Function,
    onBlur?: Function,
    placeholder?: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}
class IconInput extends React.Component<IconInputProps> {
    input: any;
    state = {
        active: false,
    };

    public focus = () => {
        this.input.focus();
    }

    handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({active: false});
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({active: true});
        if (this.props.handleFocus) {
            this.props.handleFocus(event);
        }
    }

    render() {
        const {icon, append, className, onChange, ...props} = this.props;
        const {active} = this.state;
        const Icon = icon;
        const IconComponent = <Icon color={(active && '#0A1E2C')}/>
        return (
                <div className={
                'icon-input ' +
                (className ? className : '') + ' ' +
                (active ? 'active ' : '')
                }>
                <div className="input-group">
                    {!append && IconComponent}
                    <input
                        ref={input => this.input = input}
                        className={append ? 'append-icon' : ''}
                        onChange={onChange}
                        {...props}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                    />
                    {append && IconComponent}
                </div>
            </div>
        );
    }
}

export default IconInput;