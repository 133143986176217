import React from 'react';
import NavHeader from '../../shared/NavHeader';
import './ChargeStarted.scss';
import { ChargingIcon } from '../../shared/svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import ApiService from 'services/api';
import moment from 'moment';
import { CarImage } from 'shared/CarImage';
import { ChargeSession } from 'models/ChargeSession';

interface ChargeStartedProps extends RouteComponentProps {}
interface ChargeStartedState {
    currentSession: ChargeSession | null
}
/**
* @name ChargeStarted
* @props ChargeStartedProps
* @summary Routed Component that renders a confirmation that a charge session has started.
*/
export default class ChargeStarted extends React.Component<ChargeStartedProps> {
    readonly state: ChargeStartedState = {
        currentSession: null
    }

    componentWillMount() {
        ApiService.getChargeSessions()
            .then(res => {
                if (res.data && res.data.length > 0) {
                    this.setState({currentSession: res.data[0]});
                } else {
                    this.props.history.push('/dashboard');
                }
            });
    }
    render() {
        if (!this.state.currentSession) {
            return null;
        }
        const session = this.state.currentSession!;
        return (
            <div id="charge-started-scene" className="scene-layout">
                <NavHeader />
                <div className="img-container">
                    <CarImage car={session.user_car.car} />
                    <div className="overlay">
                        <div className="icon-wrapper"><ChargingIcon/></div>
                    </div>
                </div>
                <p><b>{session.user_car.nickname}</b> is now charging</p>
                <div className="details">We’ve sent a confirmation to your email address.</div>
                <div className="completion-time">
                    <h5>Estimated Completion Time</h5>
                    <h1>{moment(session.end_time).format('LT')}</h1>
                </div>
                <Link to="/dashboard" className="btn btn-link"><button className="btn btn-primary">Return to Dashboard</button></Link>
            </div>
        );
    }
}