import React from 'react';
import { AccountIcon } from '../svg';
import './style.scss';
import State from 'services/state';
import User from 'models/User';
import { Link } from 'react-router-dom';
import { Releases } from 'models/Release';
import ApiService from 'services/api';
import Whitelabel from 'models/Whitelabel';
const env: any = process.env;
const release = env.REACT_APP_RELEASE;

interface NavHeaderState {
    profile: User | null
    whitelabel: Whitelabel | null
}
export default class NavHeader extends React.Component {
    state: NavHeaderState = {
        profile: null,
        whitelabel: null
    }
    componentWillMount() {
        State.getProfile().then(profile => this.setState({profile: profile}))
        State.getWhitelabel().then(wl => this.setState({whitelabel: wl}))
    }

    render() {
        const { profile, whitelabel } = this.state;
        return(
            <div id="nav-header">
                <Link to="/"><img src={whitelabel ? whitelabel.path : ''} alt="logo" width="300px" /></Link>
                <Link to="/account" className="contact-wrapper">
                    {profile && <div className="name">{profile.firstName} {profile.lastName}</div> }
                    <AccountIcon/>
                </Link>
            </div>
        );
    }
}
