import React from 'react';
import './SignUp.scss';
import AuthService from 'services/auth';
import User from 'models/User';
import { RouteComponentProps } from 'react-router';
import ProfileForm from 'shared/ProfileForm';

interface SignUpProps extends RouteComponentProps {}
interface SignUpState {
    signUpError: string | null
}
/**
* @name SignUp
* @props SignUpProps
* @summary Routed Component that renders the sign up form.
*/
export default class SignUp extends React.Component<SignUpProps> {
    readonly state: SignUpState = {
        signUpError: null
    }
    onSubmit = (values: any) => {
        this.setState({signUpError: null});
        const name = values.fullName.split(' ');
        const firstName = name[0];
        const lastName = name.length > 1 ? name[1] : '';
        const user: User = {
            firstName: firstName,
            lastName: lastName,
            phone: values.phone,
            email: values.email,
            password: values.password,
            roleName: 'driver'
        };
        AuthService.signUp(user)
            .then(_ => this.props.history.push('/dashboard'))
            .catch(error => this.setState({signUpError: error.response.data}));
    }
    getLogoSrc = (): string => {
        return require('img/logos/demo/logo.png');
        /*
        if (release === Releases.ENVIRO_SPARK) {
            return require('img/logos/enviro_spark/logo.png');
        } else if (release === Releases.DEMO) {
            return require('img/logos/demo/logo.png');
        }
        return require('img/logos/teq_charging/logo-header.png');
        */
    }
    render() {
        return (
            <div id="sign-up-scene" className="scene-layout">
                <img id="logo" src={this.getLogoSrc()} alt="logo" />
                <h3>Create an Account</h3>
                {this.state.signUpError && <div className="alert danger">{this.state.signUpError}</div>}
                <ProfileForm submitText='Create My Account' onSubmit={this.onSubmit} showDisclaimer={true} />
            </div>
        );
    };
}
