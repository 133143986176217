import React from 'react';
import './style.scss';
import MaskedInput from 'react-text-mask';

export default class FormInput extends React.Component<any> {
    render() {
        const {field, form: {touched, errors}, mask, ...props} = this.props;
        const invalid = (touched[field.name] && errors[field.name]);
        return(
            <div className="form-input-group">
                {!mask ?
                    <input {...field} {...props} type={props.type ? props.type : 'text'}
                    className={(props.className ? props.className : '') + ' ' + (invalid ? 'invalid' : '')} />
                    :
                    <MaskedInput mask={mask} {...field} {...props} type={props.type ? props.type : 'text'}
                        className={(props.className ? props.className : '') +' ' +(invalid ? ' invalid' : '')
                    } />
                }
                {invalid && <div className="error">{errors[field.name]}</div>}
            </div>
        );
    }
}