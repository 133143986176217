import React from 'react';
import './style.scss';
import MaskedInput from 'react-text-mask';
import { FieldProps } from 'formik';

interface FormIconInputProps extends FieldProps {
    icon: any,
    mask?: any[],
    append?: boolean,
    className?: string,
    handleFocus?: Function
};
class FormIconInput extends React.Component<FormIconInputProps> {
    input: any;
    state = {
        active: false,
    };

    public focus = () => {
        this.input.focus();
    }

    handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({active: false});
        if (this.props.field.onBlur) {
            this.props.field.onBlur(event);
        }
    }

    handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({active: true});
        if (this.props.handleFocus) {
            this.props.handleFocus(event);
        }
    }

    render() {
        const {
            field,
            form: {touched, errors},
            mask,
            icon,
            append,
            className,
            ...props} = this.props;
        const Icon = icon;
        const invalid = (touched[field.name] && errors[field.name]);
        const { active } = this.state;
        return (
            <div className="icon-input-wrapper">
                <div className={
                'icon-input ' +
                (className ? className : '') + ' ' +
                (active ? 'active ' : '') +
                (invalid ? 'invalid' : '')}>
                <div className="input-group">
                    {!append &&
                        <Icon color={
                            (active && '#0A1E2C') || (invalid && '#DB3434')
                        }/>
                    }
                    {!mask &&
                    <input
                        ref={input => this.input = input}
                        className={append ? 'append-icon' : ''}
                        {...props}
                        name={field.name}
                        onChange={field.onChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                    />
                    }
                    {mask &&
                    <MaskedInput
                        mask={mask}
                        ref={input => this.input = input}
                        className={append ? 'append-icon' : ''}
                        {...props}
                        name={field.name}
                        onChange={field.onChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                    />
                    }
                    {append &&
                        <Icon color={
                            (active && '#0A1E2C') || (invalid && '#DB3434')
                        }/>
                    }
                </div>
            </div>
                {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
            </div>
        );
    };
}
export default FormIconInput;